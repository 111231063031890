import { Suspense, lazy } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Loader } from "./screens/common";
import "./App.scss";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const Validator = lazy(() => import("./screens/ValidatorScreen/ValidatorScreen"));
const Form = lazy(() => import("./screens/FormScreen/FormScreen"));

function App() {
  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <QueryClientProvider client={queryClient}>
          <div className="App">
            <Switch>
              <Route exact path="/" component={Validator} />
              <Route path="/new-building-form" component={Form} />
              <Redirect path="*" to="/" />
            </Switch>
          </div>
        </QueryClientProvider>
      </Suspense>
    </Router>
  );
}

export default App;
