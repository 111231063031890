import cx from 'classnames';
import './ContentContainer.scss';

export type ContentContainerProps = {
  children: React.ReactNode;
  className?: string;
};

export const ContentContainer = ({
  children,
  className,
}: ContentContainerProps) => {
  return <div className={cx('ContentContainer', className)}>{children}</div>;
};
