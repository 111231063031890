import { ReactComponent as LunLogo } from "./lun-logo.svg";
import { ContentContainer } from "../ContentContainer";
import "./Header.scss";

export type HeaderProps = {
  name?: string;
};

export const Header = ({ name }: HeaderProps) => {
  return (
    <div className="Header">
      <ContentContainer className="HeaderContent">
        <div className="HeaderLogo">
          <LunLogo className="HeaderLogo-image" />
          {name && <span className="HeaderLogo-name">{name}</span>}
        </div>
      </ContentContainer>
    </div>
  );
};
